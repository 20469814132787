import { Button, Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { useContext } from 'react';
import { useStyles } from './loadingModal.styles';
import { MsgModalContext } from './msgModalContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IMsgModalProps {
  openModal: boolean;
  setOpenModal: any;
}

const MsgModal = () => {
const classes = useStyles();
  const { openModal, setOpenModal, text } = useContext(MsgModalContext);
  const handleClose = () => {
    setOpenModal(false);
  };


  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Dissmiss
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MsgModal;

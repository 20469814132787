import { proxy } from "valtio";

export interface State {
  logo: string | null;
  companyName: string;
  secondaryColor: string;
  header: string | null;
  paragraph: string | null;
};

export const initialState: State = {
  logo: null,
  companyName: "IFI",
  secondaryColor: "#000",
  header: null,
  paragraph: null,
};

export const state = proxy({ ...initialState });

export function reset() {
  Object.assign(state, initialState);
}

import { useState } from 'react';
import { IMsgModal, MsgModalContext } from './msgModalContext';
import MsgModal from './msgModal';

const MsgModalWrapper = (props: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const value: IMsgModal = { openModal, setOpenModal, text, setText };

  return (
    <MsgModalContext.Provider value={value}>
      <MsgModal />
      {props.children}
    </MsgModalContext.Provider>
  );
};
export default MsgModalWrapper;

import { Grid, ListItem, Typography } from '@material-ui/core';
import { Group } from '@mantine/core';
import { Auth } from 'aws-amplify';
import PersonIcon from '../../shared/icons/personIcon';
import { 
  landingWelcome,
} from '../../shared/utils/textConstants';
import { Typography as Icon } from '../../shared/theme/styles.enum';
import { useStyles } from './landing.styles';
import Link from '../../components/link/link';
import { useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { useSnapshot } from 'valtio';
import { state } from '../../shared/store';

const Landing = () => {
  const classes = useStyles();
  const snap = useSnapshot(state);

  useEffect(() => {
    try {
      const hostname = localStorage.getItem('hostname');
      if (hostname && process.env.REACT_APP_ENV !== 'local') {
        const path = `https://${hostname}.${
          window.location.host.split('.').slice(1).join('.') +
          window.location.pathname
          }`;
        if (window.location.href !== path) window.location.replace(path);
      }
    } catch (e) {
      Sentry.captureException(e)
      console.log(e);
    }
    // eslint-disable-next-line
  }, []);

  const signInEvent = async () => {
    try {
      await Auth.federatedSignIn();
    } catch (e) {
      Sentry.captureException(e)
      console.log(e);
    }
  };

  return (
    <Grid container className={classes.grid}>
      <Grid container item md={12} lg={7} direction="column">
        <p
          className={classes.paragraph}
          dangerouslySetInnerHTML={{ __html: snap.paragraph || "" }}
        />
      </Grid>
      <Grid container item md={12} lg={5} direction="column" className={classes.fullHeight}>
        <div className={classes.container}>
          {snap.logo && <img src={snap.logo}  alt="" className={classes.logo} />}

          <Typography className={classes.title}>
            {landingWelcome("")}
          </Typography>
          <Group>
            <ListItem
              className={classes.buttonStyle}
              onClick={signInEvent}
            >
              <PersonIcon
                alt="person"
                className={classes.iconButton}
                size={Icon.BasicText}
              />
              <Typography className={classes.buttonText}>Log In</Typography>
            </ListItem>
            <Link
              to="/selfApply"
              className={classes.linkStyle}
              style={{ backgroundColor: snap.secondaryColor }}
            >
              <Typography className={classes.buttonText}>
                New Account
              </Typography>
            </Link>
          </Group>
        </div>
      </Grid>
      <Grid container item md={12} lg={12} direction="row">
        <div className={classes.legal}>
          Copyright: © 2023 IFI Network Inc. All rights reserved.
          <br></br>
          <br></br>
          The IFI&nbsp;product names and logo are trademarks of IFI&nbsp;Network Inc. All other trademarks, logos, marks, and brand names are the property of their respective owners — used with permission.
          <br></br>
          <br></br>
          <strong>IFI&nbsp;Network is not a bank.</strong> Customer funds are held in various custodial deposit accounts. Each customer authorizes the Custodian Bank, through IFI, to hold the customer’s funds in such accounts, in a custodial capacity, in order to effectuate the customer’s deposits to and withdrawals from the various bank products that the customer requests. The Custodian Bank does not establish bank terms, and does not provide advice to customers about bank products offered through ifinetwork.com. Northern Trust is the Custodian Bank.</div>
      </Grid>
    </Grid>
  );
};

export default Landing;

import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/loading/loading';
import PageHeader from '../../../components/pageHeader/pageHeader';
import { IAddress } from '../../../shared/interfaces/iaddress.interface';
import { IHildaPendingClientFields } from '../../../shared/interfaces/iform';
import { IHilda } from '../../../shared/interfaces/ihilda.interface';
import { IUser } from '../../../shared/interfaces/iuser.interface';
import { convertDateFormat } from '../../../shared/utils/gridUtils';
import { GET_USERS_BY_HILDA } from './hildaPendingClient.service';
import { useStyles } from './hildaPendingClientInfo.styles';
import HildaPendingClientWorkflow from './hildaPendingClientWorkflow/hildaPendingClientWorkflow';

const HildaPendingClientInfo = () => {
  const { hildaAccountId }: any = useParams();
  const classes = useStyles();
  const { data, loading, error, refetch } = useQuery(GET_USERS_BY_HILDA, {
    variables: {
      hildaId: hildaAccountId,
      whereHilda: { id: hildaAccountId },
    },
  });

  useEffect(() => {
    refetch({ hildaId: hildaAccountId, whereHilda: { id: hildaAccountId } });
    // eslint-disable-next-line
  }, [hildaAccountId]);

  if (loading)
    return (
      <div className={classes.loading}>
        <Loading isLoading />
      </div>
    );

  if (error) return null;

  const personalInformationArr: IUser[] = data.usersByHildaId;
  const hilda: IHilda = data.hilda;
  const accountInfo = {
    accountNumber: hilda.id || '',
    joined: hilda.createdAt || '',
  };
  const advisor: IUser = hilda.advisor;
  const advisorName = advisor.firstName + ' ' + advisor.lastName;
  const accountType = hilda.hildaTypeId || '';
  const address: IAddress = data.hilda.address;

  const hildaAccountInfo: IHildaPendingClientFields = {
    accountType: accountType || '',
    firstName: personalInformationArr[0].firstName || '',
    lastName: personalInformationArr[0].lastName || '',
    ssn: hilda.taxId || '',
    dateOfBirth: convertDateFormat(personalInformationArr[0].dateOfBirth),
    phoneNumber: personalInformationArr[0].phoneNumber || '',
    email: personalInformationArr[0].email || '',
    address: address?.streetAddress1?.toString() || '',
    firstName2: personalInformationArr[1]?.firstName || '',
    lastName2: personalInformationArr[1]?.lastName || '',
    ssn2: hilda.secondaryTaxId || '',
    dateOfBirth2: convertDateFormat(personalInformationArr[1]?.dateOfBirth),
    phoneNumber2: personalInformationArr[1]?.phoneNumber || '',
    email2: personalInformationArr[1]?.email || '',
    address2: address?.streetAddress1?.toString() || '',
    entityName: hilda.name || '',
    taxId: hilda.taxId || '',
    accountTitle: hilda.accountTitle || '',
    accountAccess: '',
    trustDocumentURL: hilda.trustDocumentURL || '',
    signerName: '',
    signDate: convertDateFormat(hilda.createdAt),
    entityEmail: hilda.email || '',
    zipCode: address?.zipCode?.toString() || '',
    city: address?.city?.toString() || '',
    state: address?.state?.toString() || '',
  };

  const userIds = {
    userId: personalInformationArr[0]?.id,
    userId2: personalInformationArr[1]?.id,
  };

  return (
    <>
      <PageHeader
        title={hilda.name || ''}
        accountInfo={accountInfo}
        advisorName={advisorName}
      />
      <HildaPendingClientWorkflow
        hildaAccountInfo={hildaAccountInfo}
        hildaId={hildaAccountId}
        userIds={userIds}
      />
    </>
  );
};

export default HildaPendingClientInfo;

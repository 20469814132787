import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Menu } from "@material-ui/core";
import { Auth } from "aws-amplify";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./userOptions.styles";
import MenuOptions from "../../menu/menu";
import { UserContext } from "../../../shared/context/userContext";
import { MenuIndex } from "../../menu/menu.enum";
import sharedStorage from "../../../shared/utils/sharedStorage";
import { GlobalMessageContext } from "../../../shared/context/globalMessage.context";
import { useHistory } from "react-router-dom";

interface ISelectMenuProps {
  imageUrl?: string;
}

const SelectMenu: FunctionComponent<ISelectMenuProps> = (
  props: ISelectMenuProps
) => {
  const { showMessage } = useContext(GlobalMessageContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [iconRotation, setIconRotation] = useState<string>("rotate(270deg)");
  const classes = useStyles({ iconRotation });
  const { userData } = useContext(UserContext);
  const username = userData.user.firstName + " " + userData.user.lastName;
  const isSignedIn = userData.authState === "signedIn";
  const router = useHistory();

  useEffect(() => {
    if (anchorEl === null) {
      setIconRotation("rotate(-90deg)");
    } else {
      setIconRotation("rotate(0deg)");
    }
  }, [anchorEl]);

  const openMenuEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const signOutEvent = async () => {
    setAnchorEl(null);
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      await Auth.signOut();
    }
  };

  const activateTOTPEvent = () => {
    router.push("/profile")
  }

  const closeMenuEvent = () => {
    setAnchorEl(null);
  };

  const getMenuItems = () => {
    if (isSignedIn) {
      return [
        {
          id: "a",
          name: "Activate MFA",
          onClick: activateTOTPEvent
        },
        {
          id: "b",
          name: "Logout",
          onClick: async () => {
            showMessage("Logging you out");
            await sharedStorage.loadStorages();
            await sharedStorage.clearAllStorages();
            signOutEvent();
          },
        },
      ];
    }
  };

  return (
    <div>
      {isSignedIn && (
        <Button className={classes.dropdownItem} onClick={openMenuEvent}>
          {username}
          <ExpandMoreIcon className={classes.expandMoreIcon} />
        </Button>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenuEvent}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuOptions
          type={MenuIndex.UserOptions}
          orientation="vertical"
          menuItems={getMenuItems()}
          permissions={userData.user.role.permissions}
        />
      </Menu>
    </div>
  );
};

export default SelectMenu;

import { ChangeEvent, useState } from 'react';
import { FormControl, FormGroup, TextField } from '@material-ui/core';
import { useStyles } from './signature.styles';
import {
  SIGNATURE_TITLE,
  SIGNATURE_SUBTITLE,
  REQUIRED_FIELD_MISSING,
} from '../../../shared/utils/textConstants';
import {
  Errors,
  ISignatureFields,
  Types,
} from '../../../shared/interfaces/iform';
import { isFormInputValid } from '../../../shared/utils/formUtils';
import { FormTypes } from '../../../shared/interfaces/formTypes.enum';

const errorsDefault: Errors = {
  signerName: false,
};

const types: Types = {
  signerName: FormTypes.Text,
};
interface IProps {
  id: string;
  formFields: ISignatureFields;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => void;
}

const SignatureForm = (props: IProps) => {
  const { id, formFields, handleSetStepCanAdvance, handleValueChange } = props;
  const [errors, setErrors] = useState(errorsDefault);
  const classes = useStyles();

  const currentDate = new Date().toLocaleDateString();

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    validateNextStep();
  };

  const validateNextStep = () => {
    const isFormValid =
      Object.entries(types).filter(
        ([key, value]) => !isFormInputValid(value, formFields[key])
      ).length === 0;
    if (isFormValid) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  // const getSignerName = (formFields: ISignatureFields) => {
  //   if (formFields.signerName === '') {
  //     return formFields.accountType === HildaType.Individual ||
  //       formFields.accountType === HildaType.Joint
  //       ? `${formFields.firstName} ${formFields.lastName}`
  //       : formFields.signerName;
  //   }
  //   return formFields.signerName;
  // };

  return (
    <div>
      <div className={classes.title}>{SIGNATURE_TITLE}</div>
      <br />
      <div className={classes.subtitle}>
        <p>{SIGNATURE_SUBTITLE}</p>
      </div>
      <br />
      <FormControl component="fieldset">
        <FormGroup row>
          <TextField
            id="signerName"
            name="signerName"
            label="Full Name"
            variant="outlined"
            style={{ width: "20rem" }}
            className={classes.textField}
            InputLabelProps={{
              className: classes.textFieldInput,
            }}
            size="small"
            error={errors.signerName}
            value={formFields.signerName}
            onChange={(e) => handleValueChange(e, types[e.target.name])}
            onBlur={handleOnBlur}
            helperText={errors.signerName && REQUIRED_FIELD_MISSING}
          />
          <TextField
            id="signDate"
            name="signDate"
            label="Date"
            variant="outlined"
            placeholder="MM/DD/YYYY"
            disabled
            style={{ width: "10rem" }}
            className={classes.textField}
            InputLabelProps={{
              className: classes.textFieldInput,
            }}
            size="small"
            value={currentDate}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default SignatureForm;

import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useStyles } from '../personalInformation.styles';
import {
  Errors,
  IPersonalInformationInstitutionalFields,
  Types,
} from '../../../../shared/interfaces/iform';
import { isFormInputValid } from '../../../../shared/utils/formUtils';
import { FormTypes } from '../../../../shared/interfaces/formTypes.enum';
import { PERSONAL_INFORMATION, REQUIRED_FIELD_MISSING } from '../../../../shared/utils/textConstants';

const errorsDefault: Errors = {
  entityName: false,
  taxId: false,
  accountTitle: false,
  phoneNumber: false,
  entityEmail: false,
  address: false,
  firstName: false,
  lastName: false,
  email: false,
  zipCode: false,
  city: false,
  state: false
};

const types: Types = {
  entityName: FormTypes.Text,
  taxId: FormTypes.TaxId,
  accountTitle: FormTypes.AccountTitle,
  phoneNumber: FormTypes.Phone,
  entityEmail: FormTypes.EntityEmail,
  address: FormTypes.Address,
  firstName: FormTypes.Text,
  lastName: FormTypes.Text,
  email: FormTypes.Email,
  zipCode: FormTypes.ZipCode,
  city: FormTypes.Text,
  state: FormTypes.Text,
};

interface IProps {
  id: string;
  formFields: IPersonalInformationInstitutionalFields;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => void;
}

const InstitutionalAccount = (props: IProps) => {
  const { id, formFields, handleSetStepCanAdvance, handleValueChange } = props;
  const [errors, setErrors] = useState(errorsDefault);
  const [radioGroup, setRadioGroup] = useState({
    individual: true,
    other: false,
  });
  const classes = useStyles();

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [label]: !isFormInputValid(types[label], value),
      };
    });
    validateNextStep();
  };

  const validateNextStep = () => {
    const isFormValid =
      Object.entries(types).filter(
        ([key, value]) => !isFormInputValid(value, formFields[key])
      ).length === 0;
    if (isFormValid) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  const handleRadioButton = (e: any) => {
    formFields.accountTitle = '';
    const selection = e.target.value;
    if (selection === 'individual') {
      setErrors({accountTitle: false});
      setRadioGroup({ individual: true, other: false });
      handleValueChange(e, types[e.target.name]);
    } else {
      setErrors({accountTitle: true});
      setRadioGroup({ individual: false, other: true });
    }
  };

  const handleAccountTitle = (e: any) => {
    if (!radioGroup.other) setRadioGroup({ individual: false, other: true });
    handleValueChange(e, types[e.target.name]);
  };

  return (
    <FormControl className={classes.root} component="fieldset">
      <TextField
        id="entityName"
        name="entityName"
        label="Entity Name"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.entityName}
        value={formFields.entityName}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.entityName && REQUIRED_FIELD_MISSING}
      />
      <TextField
        id="taxId"
        name="taxId"
        label="Tax ID"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.taxId}
        value={formFields.taxId}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
      <fieldset className={classes.radioGroup}>
        <legend className={classes.radioGroupTitle}>
          Account Title &nbsp;*
        </legend>
        <RadioGroup
          aria-label="accountTitle"
          id="accountTitle"
          name="accountTitle"
        >
          <FormControlLabel
            value={"individual"}
            className={classes.radioButton}
            control={
              <Radio
                color="primary"
                checked={
                  radioGroup.individual ||
                  formFields.accountTitle === "individual"
                }
                onChange={handleRadioButton}
              />
            }
            label={"Individual"}
          />
          <div className={classes.otherContainer}>
            <FormControlLabel
              value={"other"}
              className={classes.radioButton}
              control={
                <Radio
                  color="primary"
                  checked={
                    radioGroup.other ||
                    (formFields.accountTitle !== "" &&
                      formFields.accountTitle !== "individual")
                  }
                  onChange={handleRadioButton}
                />
              }
              label="Other:"
            />
            <TextField
              id="accountTitle"
              name="accountTitle"
              placeholder="Name here"
              required={radioGroup.individual}
              value={
                formFields.accountTitle === "individual"
                  ? ""
                  : formFields.accountTitle
              }
              className={classes.input}
              inputProps={{ "aria-label": "other" }}
              disabled={radioGroup.individual}
              error={errors.accountTitle}
              onChange={handleAccountTitle}
              onBlur={handleOnBlur}
            />
          </div>
        </RadioGroup>
      </fieldset>
      <TextField
        id="phoneNumber"
        name="phoneNumber"
        label="Phone Number"
        variant="outlined"
        style={{ width: "11.75rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        inputProps={{ maxLength: 16 }}
        error={errors.phoneNumber}
        value={formFields.phoneNumber}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
      <TextField
        id="entityEmail"
        name="entityEmail"
        label="Entity Email"
        variant="outlined"
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.entityEmail}
        value={formFields.entityEmail}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />

      <TextField
        id="address"
        name="address"
        label="Address"
        variant="outlined"
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        required
        size="small"
        error={errors.address}
        value={formFields.address}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />

      <TextField
        id="city"
        name="city"
        label="City"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.city}
        value={formFields.city}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.city && REQUIRED_FIELD_MISSING}
      />

      <TextField
        id="state"
        name="state"
        label="State"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.state}
        value={formFields.state}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.state && REQUIRED_FIELD_MISSING}
      />

      <TextField
        id="zipCode"
        name="zipCode"
        label="Zip Code"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        inputProps={{ maxLength: 250 }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.zipCode}
        value={formFields.zipCode}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />

      <div className={classes.personalInformationTitle}>
        {PERSONAL_INFORMATION}
      </div>
      <br />
      <TextField
        id="firstName"
        name="firstName"
        label="First Name"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.firstName}
        value={formFields.firstName}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.firstName && REQUIRED_FIELD_MISSING}
      />
      <TextField
        id="lastName"
        name="lastName"
        label="Last Name"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.lastName}
        value={formFields.lastName}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
        helperText={errors.lastName && REQUIRED_FIELD_MISSING}
      />
      <TextField
        id="email"
        name="email"
        label="Email"
        variant="outlined"
        required
        style={{ width: "20rem" }}
        className={classes.textField}
        InputLabelProps={{
          className: classes.textFieldInput,
        }}
        size="small"
        error={errors.email}
        value={formFields.email}
        onChange={(e) => handleValueChange(e, types[e.target.name])}
        onBlur={handleOnBlur}
      />
    </FormControl>
  );
};

export default InstitutionalAccount;

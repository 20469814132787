import React, { ChangeEvent, useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useStyles } from './accountType.styles';
import { IAccountTypeFields } from '../../../shared/interfaces/iform';
import { ACCOUNT_TYPE_TITLE } from '../../../shared/utils/textConstants';
import { FormTypes } from '../../../shared/interfaces/formTypes.enum';
import { HildaType } from '../../../shared/interfaces/hildaType.enum';

interface IProps {
  id: string;
  formFields: IAccountTypeFields;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
  handleValueChange: (
    e: ChangeEvent<HTMLInputElement>,
    type?: FormTypes
  ) => void;
}

const AccountTypeForm = (props: IProps) => {
  const { id, formFields, handleSetStepCanAdvance, handleValueChange } = props;
  const classes = useStyles();

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [formFields.accountType]);

  const validate = () => {
    if (
      formFields.accountType === HildaType.Individual ||
      formFields.accountType === HildaType.Joint ||
      formFields.accountType === HildaType.Trust ||
      formFields.accountType === HildaType.Institutional
    ) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  return (
    <div>
      <div className={classes.title}>{ACCOUNT_TYPE_TITLE}</div>
      <br />
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="accountType"
          name="accountType"
          value={formFields.accountType}
          onChange={(e) => handleValueChange(e)}
        >
          <FormControlLabel
            value={HildaType.Individual}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label="Individual"
            title="Individual"
          />
          <FormControlLabel
            value={HildaType.Joint}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label="Joint Account"
            title="Joint Account"
          />
          <FormControlLabel
            value={HildaType.Trust}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label="Trust"
            title="Trust"
          />
          <FormControlLabel
            value={HildaType.Institutional}
            className={classes.radioButton}
            control={<Radio color="primary" />}
            label="Institutional Account"
            title="Institutional Account"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default AccountTypeForm;

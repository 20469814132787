import { useEffect } from "react";
import { gql, useApolloClient } from '@apollo/client';
import UserContextProvider from "./shared/context/userContext";
import Routes from "./shared/routes/routes";
import { GlobalOverrideCss } from "./App.styles";
import ToastWrapper from "./components/toast/toast";
import LoadingModalWrapper from "./components/loadingModal/loadingModalWrapper";
import MsgModalWrapper from "./components/msgModal/msgModalWrapper";
import "react-toastify/dist/ReactToastify.css";
import GlobalMessageProvider from "./shared/context/globalMessage.context";
import ThemeProvider from "./shared/context/theme.context";
import { MantineProvider } from '@mantine/core';
import { state } from "./shared/store";

const GET_ADVISOR_FIRM_CONFIG = gql`
  query advisorFirmConfig($advisorSubdomain:String){
    advisorFirmConfigNoAuth(advisorSubdomain:$advisorSubdomain){
      logo
      secondaryColor
      header
      paragraph
      AdvisorFirm {
        companyName
      }
    }
  }
`;

const App = () => {
  const url = window.location.href;
  const subdomainRegExp = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
  const subdomain = url.match(subdomainRegExp)?.[1];
  const client = useApolloClient();

  useEffect(
    () => {
      client
        .query({
          query: GET_ADVISOR_FIRM_CONFIG,
          variables: { advisorSubdomain: subdomain }
        })
        .then(({ data }) => {
          state.logo = data.advisorFirmConfigNoAuth.logo;
          if (data.advisorFirmConfigNoAuth.AdvisorFirm?.companyName) {
            state.companyName = data.advisorFirmConfigNoAuth.AdvisorFirm?.companyName;
          }
          if (data.advisorFirmConfigNoAuth.secondaryColor) {
            state.secondaryColor = data.advisorFirmConfigNoAuth.secondaryColor;
          }
          state.header = data.advisorFirmConfigNoAuth.header;
          state.paragraph = data.advisorFirmConfigNoAuth.paragraph;
        });
    },
    [client, subdomain],
  );

  return (
    <MantineProvider>
      <ThemeProvider>
        <GlobalMessageProvider>
          <UserContextProvider>
            <GlobalOverrideCss />
            <LoadingModalWrapper>
            <MsgModalWrapper>
                <Routes />
                <ToastWrapper />
              </ MsgModalWrapper >
            </LoadingModalWrapper>
          </UserContextProvider>
        </GlobalMessageProvider>
      </ThemeProvider>
    </MantineProvider>
  );
};

export default App;

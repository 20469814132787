import React from 'react';

export interface IMsgModal {
  openModal: boolean;
  text: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setText: React.Dispatch<React.SetStateAction<string>>;
}

export const defaultMsgModalContext: IMsgModal = {
  openModal: false,
  text: "",
  setOpenModal: () => {},
  setText: () => {}
};

export const MsgModalContext = React.createContext(
  defaultMsgModalContext
);

import validator from 'validator';
import { AsYouType } from 'libphonenumber-js';
import { IForm } from '../interfaces/iform';
import { FormTypes } from '../interfaces/formTypes.enum';

export const formatFormInput = (
  type: FormTypes | null = null,
  value: string
) => {
  switch (type) {
    case FormTypes.SSN:
      return formatSSN(value);
    case FormTypes.Date:
      return formatDate(value);
    case FormTypes.Phone:
      return formatPhoneNumber(value);
    case FormTypes.TaxId:
      return formatTaxId(value);
    case FormTypes.AccountNumber:
      return formatAccountNumber(value);
    case FormTypes.Alphanumeric:
      return formatAlphanumeric(value);
    default:
      return value;
  }
};

const formatSSN = (ssn: string) => {
  ssn = ssn.replace(/[^\d-]/g, '');
  ssn = ssn.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2');
  ssn = ssn.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');
  ssn = ssn
    .split('')
    .filter((val, idx) => {
      return val !== '-' || idx === 3 || idx === 6;
    })
    .join('');
  return ssn.substring(0, 11);
};

const formatDate = (date: string = '') => {
  date = date.replace(/[^\d/]/g, '');
  date = date.replace(/^(\d{2})\/?(\d{1,2})/, '$1/$2');
  date = date.replace(/^(\d{2})\/?(\d{2})\/?(\d{1,4})/, '$1/$2/$3');
  date = date
    .split('')
    .filter((val, idx) => {
      return val !== '/' || idx === 2 || idx === 5;
    })
    .join('');
  return date.substring(0, 10);
};

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  phoneNumber = phoneNumber.toString();
  if (phoneNumber.includes('(') && !phoneNumber.includes(')')) {
    return phoneNumber.replace('(', '');
  }
  const parseDigits = (phoneNumber: string) =>
    (phoneNumber.match(/\d+/g) || []).join('');
  const digits = parseDigits(phoneNumber).substring(0, 10);
  return new AsYouType('US').input(digits);
};

const formatTaxId = (taxId: string) => {
  return taxId.substring(0, 9);
};

const formatAccountNumber = (accountNumber: string) => {
  return accountNumber.replace(/\D/g, '');
};

const formatAlphanumeric = (string: string) => {
  return string.replace(/[^a-z0-9]/gi, '');
};

export const isFormValid = (form: IForm) =>
  Object.entries(form).filter(([key, value]) => !isFormInputValid(key, value))
    .length > 0
    ? true
    : false;

const isHexValid = (hex: string) => /^#[0-9A-F]{6}$/i.test(hex);

export const isFormInputValid = (type: string, value: string) => {
  switch (type) {
    case FormTypes.Text:
      return stringNotEmpty(value);
    case FormTypes.SSN:
      return isValidSSN(value);
    case FormTypes.Date:
      return validator.isDate(value, { format: 'MM/DD/YYYY' });
    case FormTypes.Phone:
      return true;
    case FormTypes.Email:
      return validator.isEmail(value);
    case FormTypes.EntityEmail:
      return isEmailValidOrEmpty(value);
    case FormTypes.Address:
      return stringNotEmpty(value) && isValidAddress(value);
    case FormTypes.TaxId:
      return isValidTaxId(value);
    case FormTypes.ZipCode:
      return isValidZipCode(value);
    case FormTypes.AccountNumber:
      return isValidAccountNumber(value);
    case FormTypes.BankABA:
      return isValidBankABA(value);
    case FormTypes.AccountTitle:
      return stringNotEmpty(value);
    case FormTypes.Alphanumeric:
      return validator.isAlphanumeric(value);
    case FormTypes.PrimaryColor:
      return isHexValid(value);
    case FormTypes.SecondaryColor:
      return isHexValid(value);
    default:
      return false;
  }
};

const stringNotEmpty = (value: string) => {
  if (!value) return false;
  if (value !== '' && value.length <= 40) return true;
  return false;
};

const isValidSSN = (ssn: string) => {
  const re = new RegExp(
    /(?!(\d){3}(-| |)\1{2}\2\1{4})(?!666|000|9\d{2})(\b\d{3}(-| |)(?!00)\d{2}\4(?!0{4})\d{4}\b)/gm
  );
  return re.test(ssn);
};

const isValidTaxId = (taxId: string) => {
  return taxId.length === 9;
};

function isValidZipCode(zipCode: string) {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);
}

const isValidAccountNumber = (accountNumber: string | number) => {
  if (!accountNumber) return false;
  const bankAaccountNumberLength = accountNumber.toString().length;

  return (
    bankAaccountNumberLength >= 5 &&
    bankAaccountNumberLength <= 20 &&
    !isNaN(Number(bankAaccountNumberLength))
  );
};

const isValidBankABA = (bankABA: string | number) => {
  if (!bankABA) return false;
  return bankABA.toString().length === 9 && !isNaN(Number(bankABA));
};

const isValidAddress = (value: string) => {
  return value.length > 250 ? false : true;
};

// Entity Email either empty or valid email
const isEmailValidOrEmpty = (value: string) => {
  const isEmailEmpty = value === '';
  if (isEmailEmpty) return true;
  return validator.isEmail(value);
};

import { useMutation } from '@apollo/client';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR } from '../../../../components/accountsSearch/accountsSearch.service';
import ConfirmationForm from '../../../../components/forms/confirmation/confirmation';
import PersonalInformationForm from '../../../../components/forms/personalInformation/personalInformation';
import { LoadingModalContext } from '../../../../components/loadingModal/loadingModalContext';
import StepperContainer from '../../../../components/stepper/stepperContainer';
import { triggerAlert } from '../../../../components/toast/toast';
import { uploadFileToBucket } from '../../../../components/uploadFile/uploadFile.service';
import { UserContext } from '../../../../shared/context/userContext';
import { FormTypes } from '../../../../shared/interfaces/formTypes.enum';
import {
  IHildaPendingFields,
  IStepCanAdvance,
} from '../../../../shared/interfaces/iform';
import { getPath, RouteName } from '../../../../shared/routes/routeList';
import { formatFormInput } from '../../../../shared/utils/formUtils';
import {
  ERROR,
  ERROR_ONBOARDING_MSG,
  SUCCESS,
  SUCCESS_ONBOARDING_EMAIL_MSG,
  SUCCESS_ONBOARDING_MSG,
} from '../../../../shared/utils/textConstants';
import { SUBMIT_PENDING_ADVISOR } from './hildaPendingAdvisorWorkflow.service';
import * as Sentry from "@sentry/react";
import { MsgModalContext } from '../../../../components/msgModal/msgModalContext';

const hildaPendingFormDefault: IHildaPendingFields = {
  accountType: '',
  firstName: '',
  lastName: '',
  ssn: '',
  dateOfBirth: '',
  phoneNumber: '',
  email: '',
  address: '',
  firstName2: '',
  lastName2: '',
  ssn2: '',
  dateOfBirth2: '',
  phoneNumber2: '',
  email2: '',
  address2: '',
  entityName: '',
  taxId: '',
  accountTitle: '',
  trustDocumentURL: '',
  entityEmail: '',
  city: '',
  state: '',
  zipCode :''
};

const stepCanAdvanceDefault: IStepCanAdvance = {
  personalInformation: true,
  confirmation: true,
};
interface IProps {
  hildaAccountInfo: IHildaPendingFields;
  userIds: { userId: number; userId2: number };
  hildaId: string;
}

const HildaPendingAdvisorWorkflow = (props: IProps) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const { hildaAccountInfo, userIds, hildaId } = props;
  const [formFields, setFormFields] = useState<IHildaPendingFields>(
    hildaPendingFormDefault
  );
  const [stepCanAdvance, setStepCanAdvance] = useState<IStepCanAdvance>(
    stepCanAdvanceDefault
  );

  const [submitPendingAdvisor] = useMutation(SUBMIT_PENDING_ADVISOR, {
    refetchQueries: [
      GET_ACCOUNTS_BY_STATUS_FOR_ADVISOR,
      'AccountsByHildaStatus',
    ],
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const { setOpenModal } = useContext(LoadingModalContext);
  const {  setOpenModal: setOpenMsgModal, setText } = useContext(MsgModalContext);

  const handleSetFileToUpload = (file: File) => {
    setFileToUpload(file);
  };

  useEffect(() => {
    if (hildaAccountInfo) setFormFields(hildaAccountInfo);
  }, [hildaAccountInfo]);

  const handleValueChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: FormTypes
  ) => {
    e.preventDefault();
    const label = e.target.name;
    const value = e.target.value;
    setFormFields((prevForm) => {
      return { ...prevForm, [label]: formatFormInput(type, value) };
    });
  };

  const handleSetStepCanAdvance = (id: string, flag: boolean) => {
    setStepCanAdvance((prevState) => {
      return { ...prevState, [id]: flag };
    });
  };

  const clearFormFields = () => {
    setFormFields(hildaPendingFormDefault);
  };

  const submit = async () => {
    try {
      setOpenModal(true);
      const bucketRes =
        fileToUpload && (await uploadFileToBucket(fileToUpload, 'trust'));
      await submitPendingAdvisor({
        variables: {
          userIds,
          hildaId,
          onboardingForm: {
            ...formFields,
            trustDocumentURL: bucketRes?.uploadUrl,
          },
        },
      });
      setOpenModal(false);
      setOpenMsgModal(true);
      setText(SUCCESS_ONBOARDING_EMAIL_MSG);
      triggerAlert({
        type: 'success',
        title: SUCCESS,
        msg: SUCCESS_ONBOARDING_MSG('update'),
      });
      setSubmitSuccess(true);
      setTimeout(
        () =>
          history.push(
            getPath(RouteName.Accounts, {
              advisorFirmId: userData.user.advisorFirmId,
            })
          ),
        500
      );
    } catch (e) {
      Sentry.captureException(e)
      console.error(e);
      setOpenModal(false);
      triggerAlert({
        type: 'error',
        title: ERROR,
        msg: ERROR_ONBOARDING_MSG('update'),
      });
    }
  };

  return (
    <StepperContainer
      steps={[
        {
          label: 'Personal information',
          form: (
            <PersonalInformationForm
              id="personalInformation"
              formFields={formFields}
              type={formFields.accountType}
              handleSetStepCanAdvance={handleSetStepCanAdvance}
              handleValueChange={handleValueChange}
              handleSetFileToUpload={handleSetFileToUpload}
            />
          ),
          buttonBack: false,
          nextValidated: stepCanAdvance.personalInformation,
        },
        {
          label: 'Confirmation',
          form: (
            <ConfirmationForm
              displayTitle={false}
              hildaAccountInfo={{ ...formFields, advisor: userData.user }}
            />
          ),
          buttonBack: true,
          nextValidated: stepCanAdvance.confirmation,
        },
      ]}
      startOver={false}
      clearFormFields={clearFormFields}
      handleSubmit={submit}
      submitSuccess={submitSuccess}
    />
  );
};

export default HildaPendingAdvisorWorkflow;
